// Import 3rd-party dependencies
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { TeamsMsal2Provider } from '@microsoft/mgt';

// Import project-specific dependencies
import { TeamsAuthGuard } from '@app/auth/teams/teams-auth.guard';
import { MOBILE_WARNING_CONSTS } from '@app/config/constants';

const AuthGuard = TeamsMsal2Provider.isAvailable ? TeamsAuthGuard : MsalGuard;
// Detect device and Edge browser
const isMobile: boolean = MOBILE_WARNING_CONSTS.DETECT_DEVICE;
const isEdgeBrowser =
  window.navigator.userAgent.toLowerCase().indexOf('edge') > -1 ||
  window.navigator.userAgent.toLowerCase().indexOf('edg') > -1;

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled'
};

const routes: Routes = [
  // App routes
  {
    path: '',
    loadChildren: () => import('@app/hub/hub.module').then((m) => m.HubModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'people-search',
    loadChildren: () => import('@app/ps/people-search.module').then((m) => m.PeopleSearchModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('../app/modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

// Mobile app routes
const mobileRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('src/app/modules/mobile-warning/mobile.module').then((m) => m.MobileModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(
      (isMobile && !isEdgeBrowser)  || (TeamsMsal2Provider.isAvailable && isMobile) ? mobileRoutes : routes,
      routerOptions
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
