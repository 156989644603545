// Import 3rd-party dependencies
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  InteractionType,
  Configuration
} from '@azure/msal-browser';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Import project-specific dependencies
import { environment } from '@app/environments/environment';
import ProtectedResources from '@app/auth/protected-resources';

const msalConfig: Configuration = {
  auth: {
    clientId: environment.AZURE.AD.CLIENT_ID,
    authority: environment.AZURE.AD.AUTHORITY,
    redirectUri: '/'
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    secureCookies: true
  }
};

export function MsalInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MsalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: ProtectedResources
  };
}

export function MsalGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['https://graph.microsoft.com/.default']
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule, MsalModule],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MsalInstanceFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalInterceptorConfigFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfigFactory
    },
    {
      provide: 'MSAL_CONFIG',
      useValue: msalConfig
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [MsalRedirectComponent]
})
export class MsalAuthModule {}
