// Import 3rd-party dependencies
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

type KeyStringParam = { [key: string]: string | string[] };

@Injectable({
  providedIn: 'root'
})
export abstract class ApiService {
  constructor(private httpClient: HttpClient) {}

  protected get<T>(
    path: string,
    params?: HttpParams | KeyStringParam,
    headers?: HttpHeaders | KeyStringParam
  ): Observable<T> {
    return this.httpClient.get<T>(path, {
      params: params || {},
      headers: headers || {}
    });
  }

  protected post<T>(
    path: string,
    body: unknown,
    params?: HttpParams | KeyStringParam,
    headers?: HttpHeaders | KeyStringParam
  ): Observable<T> {
    return this.httpClient.post<T>(path, body, {
      params: params || {},
      headers: headers || {}
    });
  }

  protected put<T>(
    path: string,
    body: unknown,
    params?: HttpParams | KeyStringParam,
    headers?: HttpHeaders | KeyStringParam
  ): Observable<T> {
    return this.httpClient.put<T>(path, body, {
      params: params || {},
      headers: headers || {}
    });
  }

  protected delete<T>(
    path: string,
    params?: HttpParams | KeyStringParam,
    headers?: HttpHeaders | KeyStringParam
  ): Observable<T> {
    return this.httpClient.delete<T>(path, {
      params: params || {},
      headers: headers || {}
    });
  }
}
