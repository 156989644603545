// Import 3rd-party dependencies
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, switchMap } from 'rxjs/operators';

// Import project-specific dependencies
import { TeamsAuthService } from '@app/auth/teams/teams-auth.service';

@Injectable({
  providedIn: 'root'
})
export class TeamsAuthInterceptor implements HttpInterceptor {
  constructor(private authService: TeamsAuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.login(req.url).pipe(
      switchMap((token) => {
        return of(token ? req.clone({ setHeaders: { Authorization: `Bearer ${token}` } }) : req);
      }),
      switchMap((req: HttpRequest<unknown>) => {
        return next.handle(req).pipe(
          retry(1),
          catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              // server-side error
              errorMessage = `Error code: ${error.status}\nMessage: ${error.message}`;
            }
            return throwError(errorMessage);
          })
        );
      })
    );
  }
}
