// Import 3rd-party dependencies
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// Import project-specific dependencies
import { EnvService } from '@app/services/env/env.service';
import { environment } from '@app/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApprovalAuthInterceptor implements HttpInterceptor {
  private APPROVALS_URL = environment.DEPS.APPROVAL_CENTRAL.API_URL;

  constructor(private envService: EnvService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith(this.APPROVALS_URL)) {
      return this.envService.getApprovalCentralToken$.pipe(
        // Note: Removed the '/env' path access due to security reason. Reference: TH-2420 Information leak in DXCHub Azure App
        // Fetching approval central token from the hub assets folder.
        //map((env) => env['APPROVAL_BEARER_TOKEN'] || ''),
        switchMap((res) => {
          const token = res['token'] as string;
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
          return next.handle(req);
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
