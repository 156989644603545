// Import 3rd-party dependencies
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

// Import project-specific dependencies
import { ApiService } from '@app/services/api.service';
import { IGraphApiResponse } from '@app/interfaces';

type KeyStringParam = { [key: string]: string | string[] };

@Injectable({
  providedIn: 'root'
})
export class GraphApiService extends ApiService {
  getData(url: string, parameters: KeyStringParam, headers?: KeyStringParam, nextLink?: string): Observable<unknown[]> {
    return this.processNextLink(url, parameters, headers, nextLink).pipe(
      switchMap((res: IGraphApiResponse) => {
        const nextLink = res['@odata.nextLink'] || null;
        const value = res.value;

        if (nextLink) {
          // Recurse search
          return this.getData(url, parameters, headers, nextLink).pipe(map((item) => value.concat(item)));
        }

        // End recursion
        return of(value);
      })
    );
  }

  private processNextLink(
    url: string,
    parameters: KeyStringParam | string,
    headers: KeyStringParam,
    nextLink?: string
  ): Observable<IGraphApiResponse> {
    let queryParams = {};
    let requestUrl = url;

    if (nextLink) {
      requestUrl = nextLink;
    } else {
      queryParams = parameters;
    }

    return this.get<IGraphApiResponse>(requestUrl, queryParams, headers);
  }
}
