// Import 3rd-party dependencies
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Import project-specific dependencies
import { ApiService } from '@app/services/api.service';
import { environment } from '@app/environments/environment';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EnvService extends ApiService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  // Note: Removed the '/env' path access due to security reason. Reference: TH-2420 Information leak in DXCHub Azure App
  // env$ = this.get(`${environment.APP.URL.PRIVATE}/env`).pipe(
  //   map((env) => env as { [key: string]: string }),
  //   shareReplay()
  // );
  
  getApprovalCentralToken$ = this.getBearerToken();

  getBearerToken(): Observable<any> {
    return this.get(`${environment.DEPS.NOTIFICATIONS_API.URL}/notifications/approvalcentral`);
  }
}
