// Import 3rd-party dependencies
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, MailboxSettings, TimeZoneInformation } from '@microsoft/microsoft-graph-types';
import { Observable } from 'rxjs';
import { share, shareReplay } from 'rxjs/operators';

// Import project-specific dependencies
import { GraphApiService } from '@app/services/graph-api.service';
import { environment } from '@app/environments/environment';
import { PEOPLE_SERVICE_CONSTS } from '@app/config/constants';

@Injectable({
  providedIn: 'root'
})
export class PeopleService extends GraphApiService {
  private API_URL_V1 = `${environment.GRAPH.URL}/${environment.GRAPH.VERSIONS.V1}`;

  consts = PEOPLE_SERVICE_CONSTS;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  currentUser$ = this.get<User>(`${this.API_URL_V1}/me`, {
    $select: this.consts.QUERY_SELECT_USER_FIELDS
  }).pipe(share());

  managerInfo$ = this.get<User>(`${this.API_URL_V1}/me/manager`, {
    $select: this.consts.QUERY_SELECT_USER_FIELDS
  }).pipe(shareReplay());

  private getFilterFields(value: string): string {
    let orFilters = [
      `startswith(displayName,'${value}')`,
      `startswith(mailNickname,'${value}')`,
      `startswith(givenName,'${value}')`,
      `startswith(userPrincipalName,'${value}')`,
      `startswith(department,'${value}')`
    ].join(' or ');

    // Accommodate input of "first name last name" or "last name first name"
    const userNameCheckRegex = this.consts.USERNAME_CHECKER_REGEX;
    if (userNameCheckRegex.test(value)) {
      const filter = [
        `startswith(displayName,'${value.replace(userNameCheckRegex, '$2, $1')}')`,
        `startswith(displayName,'${value.replace(userNameCheckRegex, '$1, $2')}')`
      ].join(' or ');
      orFilters = [filter, orFilters].join(' or ');
    }

    // Exclude the 'guest' accounts in the search results
    const filters = [`(${orFilters})`, `userType eq '${this.consts.QUERY_USERTYPE_MEMBER}'`].join(' and ');
    return filters;
  }

  searchPeople(searchQuery: string): Observable<User[]> {
    const url = `${this.API_URL_V1}/users`;
    const parameters = {
      $select: this.consts.QUERY_SELECT_USER_FIELDS,
      $filter: this.getFilterFields(searchQuery),
      $expand: this.consts.QUERY_EXPAND_MANAGER
    };
    const headers = {
      ConsistencyLevel: this.consts.HEADER_CONSISTENCY_LEVEL
    };
    return this.getData(url, parameters, headers);
  }

  getSupportedTimezone(): Observable<TimeZoneInformation[]> {
    const url = `${this.API_URL_V1}/me/outlook/supportedTimeZones`;
    const parameters = {};
    const headers = {};
    return this.getData(url, parameters, headers);
  }
}
