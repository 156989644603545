// Import 3rd-party dependencies
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Providers, TemplateHelper, Msal2Provider, TeamsMsal2Provider, ProviderState } from '@microsoft/mgt';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SubSink } from 'subsink';

// Import project-specific dependencies
import { AppInjector } from './app.injector';
import { IconService } from '@app/services/icon/icon.service';
import { PeopleService } from '@app/services/people/people.service';
import { TeamsAuthService } from '@app/auth/teams/teams-auth.service';
import { environment } from '@app/environments/environment';
import { MOBILE_WARNING_CONSTS } from '@app/config/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  // Class attributes
  private subs: SubSink;
  title = 'hub-app';
  isMobile: boolean;
  consts = MOBILE_WARNING_CONSTS;

  constructor(private iconService: IconService, private peopleService: PeopleService) {
    this.iconService.registerIcons();
  }

  ngOnInit(): void {
    // Detect device
    this.isMobile = this.consts.DETECT_DEVICE;

    TemplateHelper.setBindingSyntax('[[', ']]');
    this.subs = new SubSink();

    // Set Graph Toolkit provider
    this.setGraphToolkitProvider();

    // Set user info
    this.subs.sink = this.peopleService.currentUser$.subscribe((user) => {
      localStorage.setItem('user', JSON.stringify(user));
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  isLoggedIn(): boolean {
    return Providers.globalProvider && Providers.globalProvider.state === ProviderState.SignedIn;
  }

  private setGraphToolkitProvider(): void {
    if (TeamsMsal2Provider.isAvailable) {
      const teamsAuthService = AppInjector.get(TeamsAuthService);
      Providers.globalProvider = teamsAuthService.getGraphToolkitProvider();
    } else {
      const destroying$ = new Subject<void>();
      const msalBroadcastService = AppInjector.get(MsalBroadcastService);
      this.subs.sink = msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(destroying$)
        )
        .subscribe(() => {
          Providers.globalProvider = new Msal2Provider({
            clientId: environment.AZURE.AD.CLIENT_ID,
            authority: environment.AZURE.AD.AUTHORITY,
            scopes: [`${environment.GRAPH.URL}/.default`]
          });
        });
    }
  }
}
