// Import 3rd-party dependencies

// Import project-specific dependencies
import { environment } from '@app/environments/environment';

/*---------------------------------------------------------*/
/*-------------------------DEFAULT-------------------------*/
/*---------------------------------------------------------*/

const HUB_DEFAULT_CONSTS = {
  POLL_INTERVAL: 10 * 60 * 1000, // 10 minutes in milliseconds unit
  DATE_INTERVAL: 1000, // 1 second in milliseconds unit
  COMPONENT_TIMEOUT: 5000, // 5 seconds in milliseconds unit
  MENU_TIMEOUT: 50, // 50 milliseconds,
  VALUE_DXCHUB: 'dxchub',
  VALUE_MYHUB: 'myhub'
};

export const DIALOG_MEASUREMENTS_CONSTS = {
  '100%': '100%',
  '400': '400px',
  '500': '500px',
  '650': '650px'
};

/*----------------------------------------------------------------*/
/*-------------------------SHARED MODULES-------------------------*/
/*----------------------------------------------------------------*/

// Shared module - URL Validator
export const URL_VALIDATOR_CONSTS = {
  URL_REGEX: /^(http|https|ftp|sftp):\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&%'\(\)\*\+,;=.]+$/
};

// Shared module - Favorites snackbar
export const FAVORITE_SNACKBAR_COMPONENT_CONSTS = {
  DATE_INTERVAL: HUB_DEFAULT_CONSTS.DATE_INTERVAL,
  VALUE_DXCHUB: HUB_DEFAULT_CONSTS.VALUE_DXCHUB,
  VALUE_MYHUB: HUB_DEFAULT_CONSTS.VALUE_MYHUB,
  LABEL_FAVORITE_ACTION: 'You favorited',
  LABEL_FAVORITE_ACTION_VIEWALL: 'View all favorites in',
  LABEL_FAVORITES_LINK: 'Favorites',
  LABEL_FAVORITE_MANY_ACTION_AND: 'and',
  LABEL_FAVORITE_MANY_ACTION_MORE: 'more.',
  LABEL_UNFAVORITE_ACTION: 'You unfavorited',
  FAVORITES_SECTION_TIMEOUT: 1500,
  OFFSET_TIMEOUT: 3000
};

// Shared module - Chip
export const CHIP_COMPONENT_CONSTS = {
  SELECTED_CHIPS_TIMEOUT: 2000,
  LABEL_RESET_SELECTION: 'RESET SELECTION'
};

// Shared module - Trending List Item
export const TRENDING_LIST_ITEM_COMPONENT_CONSTS = {
  LABELS: {
    KB_CATEGORY: 'Knowledge Base Category: ',
    CATEGORY: 'Category: ',
    KB: 'Knowledge Base: ',
    RATING: 'Rating: ',
    VIEW_COUNT: 'Views: ',
    LAST_UPDATED: 'Updated: ',
    TOPIC: 'Topic: ',
    COUNTRY: 'Country: ',
    LANGUAGE: 'Language: '
  }
};

// Shared module - Footer component
export const FOOTER_COMPONENT_CONSTS = {
  LABEL_COPYRIGHT: '© DXC Technology',
  LABEL_HELP_CENTER: 'Help center',
  LABEL_PRIVACY_POLICY: 'Privacy Policy',
  LINK_HELP: 'https://dxcportal.sharepoint.com/sites/dxchubhelp',
  LINK_PRIVACY_POLICY:
    'https://dxcportal.sharepoint.com/sites/policy/Shared%20Documents/Ethics%20and%20Compliance/Global%20Privacy%20and%20Data%20Protection%20Policy.pdf',
  DXC_LOGO: {
    BLACK: 'hub_dxc_logo_black',
    WHITE: 'hub_dxc_logo'
  }
};

// Task card component
export const TASK_CARD_COMPONENT_CONSTS = {
  TASK_LINK_LABEL: 'Go to action'
};

/*------------------------------------------------------------*/
/*-------------------------COMPONENTS-------------------------*/
/*------------------------------------------------------------*/

// Feedback component
export const FEEDBACK_COMPONENT_CONSTS = {
  ELEMENT_ID: 'ZN_6zLQ2r5iTRUIsGW',
  QUALTRICS: {
    INT: 100,
    CODE: 'r',
    ID: 'QSI_S_ZN_6zLQ2r5iTRUIsGW',
    LINK: 'https://zn6zlq2r5itruisgw-dxc.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_6zLQ2r5iTRUIsGW'
  },
  COMPLETE: 'complete',
  TYPE: 'text/javascript',
  V_CHAR: 'v',
  R_CHAR: 'r',
  EXPIRY_STRING: '; expires=',
  PATH_STRING: '; path=/;',
  CODE: 100,
  ZERO: 0,
  WEEK_IN_SECONDS: 604800 // 1 week in seconds
};

// Favorites component
export const HUB_FAVORITES_COMPONENT_CONSTS = {
  TITLE: 'Favorites',
  LABEL_NO_FAVORITES: 'You currently do not have any favorites.',
  LABEL_GO_TO_FAVORITES: 'Go to Tools and Resources to add favorites or add a custom favorite.',
  BUTTON_MANAGE: 'MANAGE FAVORITES',
  BUTTON_ADD: 'ADD FAVORITES'
};

// People Search
export const PEOPLE_SEARCH_COMPONENT_CONSTS = {
  ...DIALOG_MEASUREMENTS_CONSTS,
  ...FOOTER_COMPONENT_CONSTS,
  TITLE: 'People Search',
  PEOPLE_SEARCH_PAGINATION_MIN_ITEMS_PER_PAGE: 10,
  PEOPLE_SEARCH_PAGINATION_ITEMS_PER_PAGE: [10, 25, 50],
  PEOPLE_SEARCH_RESULTS_COLUMNS: ['surname', 'manager', 'country', 'officeLocation', 'department', 'Actions'],
  PEOPLE_SEARCH_ORGANIZATION_FILTER_ALL: 'All',
  GO_TO_DXC_HUB: 'Click to go to DXC Hub page',
  GO_TO_PEOPLE_SEARCH: 'Click to go People Search page',
  WELCOME_MESSAGE: 'Welcome to People Search',
  DESCRIPTION: 'Find a person in the organization by their First Name, Last Name, Email, Short Name or Organization',
  PLACEHOLDER_SEARCH: 'Name, email or organization',
  LABEL_FILTER: 'Filter',
  LABEL_ORG_NAME: 'Organization Name',
  LABEL_ORGANIZATION: 'Organization:',
  LABEL_SEARCH_RESULTS: 'Search results for',
  LABEL_NO_SEARCH: 'No search performed yet',
  LABEL_CURRENT_FILTERS: 'Current Filters:',
  LABEL_RESET_FILTERS: 'Reset Filters',
  LABEL_MANAGER_NAME: 'Manager Name',
  LABEL_NO_MANAGER: 'Manager not found',
  LABEL_ORGANIZATION_NAME: 'Organization Name',
  LABEL_NO_ORGANIZATION: 'Organization not found',
  LABEL_COUNTRY: 'Country',
  LABEL_NO_COUNTRY: 'Country not found',
  LABEL_OFFICE: 'Office',
  LABEL_NO_OFFICE: 'Office not found',
  LABEL_ACTIONS: 'Actions',
  LABEL_VIEW_MORE_INFO: 'View more information',
  LABEL_EMAIL: 'Email',
  LABEL_EMAIL_MANAGER: 'Email Manager',
  LABEL_TEAMS_CHAT: 'Chat in Teams',
  LABEL_TEAMS_CALL: 'Call in Teams',
  LABEL_NAME: 'Name',
  LABEL_LOCAL_TIME: 'Local Time',
  LABEL_SAME_TIMEZONE: 'Same time zone as you',
  LABEL_AHEAD: 'ahead',
  LABEL_BEHIND: 'behind',
  LINK_MAILTO: 'mailto:',
  LINK_TEAMS_CHAT: 'https://teams.microsoft.com/l/chat/0/0?users=',
  LINK_TEAMS_CALL: 'https://teams.microsoft.com/l/call/0/0?users='
};

// People search information dialog component
export const PEOPLE_SEARCH_INFO_DIALOG_COMPONENT_CONSTS = {
  ...DIALOG_MEASUREMENTS_CONSTS,
  LABEL_POSITION: 'Position',
  LABEL_CONTACT: 'Contact',
  LABEL_ORGANIZATION: 'Organization',
  LABEL_NO_ORG: 'Organization not found',
  LABEL_REPORTS_TO: 'Reports to',
  LABEL_LOCATION: 'Location',
  LABEL_WORK_LOCATION: 'Work Location',
  LABEL_ACCESS_TIME: 'Central Time (CT) (UTC-6)',
  LABEL_JOB_DETAILS: 'Job Details',
  LINK_MAILTO: PEOPLE_SEARCH_COMPONENT_CONSTS.LINK_MAILTO,
  LINK_TEAMS_CHAT: PEOPLE_SEARCH_COMPONENT_CONSTS.LINK_TEAMS_CHAT,
  LINK_TEAMS_CALL: PEOPLE_SEARCH_COMPONENT_CONSTS.LINK_TEAMS_CALL
};

// People search organization dilaog component
export const PEOPLE_SEARCH_ORG_CHART_COMPONENT_CONSTS = {
  LABEL_ORG_CHART: 'Organization Chart',
  LABEL_DIRECT_REPORTS: 'Direct Reports',
  LABEL_VIEW_MORE: 'view more (5)'
};

// Trending documents component
export const TRENDING_DOCS_COMPONENT_CONSTS = {
  MESSAGE_COPY_LINK: 'Link copied',
  LABEL_OPEN: 'Open',
  LABEL_COPY: 'Copy Link',
  SNACKBAR_DURATION: 1000
};

// Calendar Events
export const CALENDAR_EVENTS_COMPONENT_CONSTS = {
  TITLE: 'For You',
  LABEL_MAIN: 'Check out your calendar events!',
  LABEL_SUB: 'Calendar events',
  LABEL_EVENT_TODAY: 'Today',
  LABEL_EVENT_NO_LOCATION: 'No location',
  START_TIME_DIFF_MINS: 10,
  END_TIME_DIFF_MINS: 0,
  REFRESH_INTERVAL_SECS: HUB_DEFAULT_CONSTS.DATE_INTERVAL * 5, // 5 seconds
  BUTTON_JOIN: 'JOIN',
  CALENDAR_CONFIG: {
    lastDay: '[Yesterday] LT',
    sameDay: '[Today] LT',
    nextDay: '[Tomorrow] LT',
    lastWeek: '[Last week] dddd LT',
    nextWeek: '[Next week] dddd LT',
    sameElse: 'YYYY-MM-DD LT'
  } as moment.CalendarSpec,
  TOOLTIP_MESSAGE:
    'Daily events from your calendar. Click on event title for details. Join upcoming event with the "Join" button.'
};

// Work profile
export const WORK_PROFILE_COMPONENT_CONSTS = {
  TITLE: 'Work Profile',
  VIEW: 'View:',
  PROFILE_REPORT_SCALE_RATIO: 1.7,
  PROFILE_REPORT_CONTAINER_MARGIN_X: 20,
  PROFILE_REPORT_CONTAINER_MARGIN_Y: 40,
  REPORT_REFRESH_TIME: 15 * 60 * 1000, // 15 minutes in milliseconds unit
  APP_INSIGHTS_LOCATION: 'Work Profile (Overview)',
  CONFIG_TYPE: 'report',
  MYTEAM_REPORT_SCALE_RATIO: 1.6,
  TOOLTIP_MIG_BUTTON: 'Manager Information Gateway',
  TOOLTIP_MESSAGE: 'Select Skills, Projects or Time tracking to view skills, allocation, utilization and more.',
  ACCOUNT_EXECUTIVES_REPORT_SCALE_RATIO: 2.1,
  ACCOUNT_EXECUTIVES_REPORT_CONTAINER_MARGIN_X: 10,
  ACCOUNT_EXECUTIVES_REPORT_CONTAINER_MARGIN_Y: 5,
  HUB_TAB_EVENT_DELAY: 200, // 200 milliseconds unit
  EVENT_DEBOUNCETIME: 0,
  ACTIVE_REPORT_TIMEOUT: 200, // 200 milliseconds unit
  ACTIVE_REGION_REPORT_DELAY: 5000, // 5000 milliseconds unit
  PROFILE_REPORTS: {
    MY_PROFILE: {
      index: 6,
      label: 'My profile'
    },
    MY_ROLE: {
      index: 7,
      label: 'My role'
    },
    MY_REGION: {
      index: 8,
      label: 'My region'
    },
    PROJECTS: {
      index: 1,
      label: 'Projects',
      url: environment.POWERBI.REPORT_PAGE_PROJECTS
    },
    TIMETRACKING: {
      label: 'Time',
      index: 2,
      url: environment.POWERBI.REPORT_PAGE_TIMETRACKING
    },
    SKILLS: {
      index: 3,
      label: 'Skills',
      url: environment.POWERBI.REPORT_PAGE_SKILLS
    },
    MY_TEAM: {
      index: 4,
      label: 'Managers',
      url: null
    },
    ACCOUNT_EXECUTIVE: {
      index: 5,
      label: 'D3',
      url: environment.POWERBI.REPORT_ACCOUNT_EXECUTIVES
    },
    TITLE_PROFILE: 'Work profile reports',
    TITLE_MYTEAM: 'My Team report',
    MESSAGE_PROFILE: 'Select the buttons below to open the reports in your browser.',
    MESSAGE_MANAGER: function (isEMSreport: boolean): string {
      return `Select ${isEMSreport === true ? 'the Managers ' : ''}button below to open the reports in your browser.`;
    }
  },
  EMS_AAD_GROUP_ID: '8a53fe2f-2988-4ac5-aba9-678c711f6cb3', // Group id of DXCInsights_EMS-users in the AAD
  REGION_JSON_LIST: 'REGION_LIST',
  WORKPLACE_JSON_LIST: 'WORKPLACE_LIST',
  REGION_THUMBNAIL_IMAGE_URL:
    'assets/images/regionandworkplace/region/thumbnail/',
  WORKPLACE_THUMBNAIL_IMAGE_URL:
    'assets/images/regionandworkplace/workplace/thumbnail/',
  MESSAGE_COPY_LINK: TRENDING_DOCS_COMPONENT_CONSTS.MESSAGE_COPY_LINK,
  ACTION_COPY_LINK: TRENDING_DOCS_COMPONENT_CONSTS.LABEL_COPY,
  ACTION_OPEN: TRENDING_DOCS_COMPONENT_CONSTS.LABEL_OPEN,
  SNACKBAR_DURATION: TRENDING_DOCS_COMPONENT_CONSTS.SNACKBAR_DURATION
};

// Add Favorites Dialog
export const ADD_FAVORITES_DIALOG_COMPONENT_CONSTS = {
  TITLE: 'Add Favorites',
  LABEL_MAIN: 'Add from tools and resource list or add your own custom link to Favorites.',
  LABEL_TOOLS_RESOURCES_MAIN: 'Add from Tools and Resource list:',
  LABEL_TOOLS_RESOURCES_ANOTHER: 'Add Another from Tools & Resource List',
  LABEL_OR: 'OR',
  LABEL_REQUIRED: '*REQUIRED',
  LABEL_CUSTOM_URL_TITLE: 'Add a title for your custom URL:',
  ERROR_CUSTOM_URL_TITLE_REQUIRED: 'A title is required when adding a custom URL',
  LABEL_CUSTOM_URL_MAIN: 'Add Custom URL:',
  LABEL_CUSTOM_URL_ANOTHER: 'Add Another Custom URL',
  ERROR_CUSTOM_URL_REQUIRED: 'A URL is required when adding a title',
  ERROR_CUSTOM_URL_INVALID: 'URL format is invalid',
  BUTTON_CANCEL: 'CANCEL',
  BUTTON_SAVE: 'ADD FAVORITES',
  FIELDS_MAX_COUNT: 5,
  PLACEHOLDER_TITLE: 'Title',
  PLACEHOLDER_URL: 'https://'
};

// Edit Favorites Dialog
export const EDIT_FAVORITES_DIALOG_COMPONENT_CONSTS = {
  TITLE_MAIN: 'Edit Favorite',
  LABEL_MAIN: 'Edit your custom favorite.',
  LABEL_REQUIRED: '*REQUIRED',
  LABEL_EDIT_TITLE: 'Edit title:',
  LABEL_EDIT_TITLE_REQUIRED: 'A title is required when adding a custom URL',
  PLACEHOLDER_TITLE: 'Title',
  LABEL_EDIT_URL: 'Edit URL:',
  LABEL_EDIT_URL_TITLE_REQUIRED: 'A URL is required when adding a custom URL',
  LABEL_EDIT_URL_INVALID: 'URL format is invalid',
  PLACEHOLDER_URL: 'https://',
  BUTTON_CANCEL: 'CANCEL',
  BUTTON_SAVE: 'SAVE'
};

// Favorites Widget
export const FAVORITES_WIDGET_COMPONENT_CONSTS = {
  TOOLTIP_MESSAGE:
    'Your favorited tools and resources can be found here. Customize the list by adding your own favorites.',
  TOOLTIP_UNFAVORITE: 'Unfavorite',
  TOOLTIP_EDIT: 'Edit Custom Favorite',
  TOOLTIP_GOTO: 'Go to',
  TITLE: 'Favorite Links and Apps',
  LABEL_SORT: 'Sort By:',
  PLACEHOLDER_SORT: 'Select',
  LABEL_SORT_ALPHA: 'A - Z',
  LABEL_SORT_GROUP: 'Group',
  BUTTON_ADD_FAVORITES: 'Add Favorites',
  LABEL_CUSTOM_FAVORITES: 'Custom Favorites',
  LABEL_FAVORITES: 'Favorites',
  LABEL_NO_FAVORITES_MAIN: 'You currently do not have any favorites.',
  LABEL_NO_FAVORITES_SUB: 'Go to Tools and Resources to add favorites or add a custom favorite.',
  LABEL_SNACKBAR_EDIT: "You've edited"
};

// Search
export const SEARCH_COMPONENT_CONSTS = {
  OFFICE_URL: 'https://www.microsoft365.com/search?auth=2&q=',
  PLACEHOLDER_SEARCH_BOX: 'Search',
  APP_INSIGHTS_SEARCH_TYPE: 'Office Search',
  CONNECTION_HR_CONNECT: '/external/connections/hrconnectconnection',
  CONNECTION_IT_CONNECT: '/external/connections/uptimekbconnection',
  ENTERPRISE_SEARCH_LINK: 'https://dxcportal.sharepoint.com/EnterpriseSearch/',
  ENTERPRISE_SEARCH_TOOLTIP_MESSAGE:
    'Enterprise Search allows you to search for information across all of your applications and services within DXC. Click icon for more information.',
  SEARCH_WILDCARD: '*',
  DEBOUNCE_TIME: 500
};

// Quick Links
export const QUICK_LINKS_COMPONENT_CONSTS = {
  TITLE: 'Quick Links',
  MAX_SCREENSIZE: 1230,
  MAX_QUICKLINKS_COUNT: 14,
  TOOLTIP_MESSAGE: 'Quick access to important DXC resources and sites',
  COUNTRY_ALL: 'All',
  //2296-TH-2295--disable_country_selection_for_UserProfile/MyProfiles/TrendingLink(ITconnect/UPtime)
  //ITCONNECT_URL: 'help.dxc.com', // Must be without https://
  UPTIME_URL: 'uptime.dxc.com', // Must be without https://
  URL_PROTOCOL: 'http://'
};

// News
export const LATEST_NEWS_COMPONENT_CONSTS = {
  TITLE: 'DXC News',
  MY_DXC_LINK: 'https://my.dxc.com/',
  MY_DXC_REDIRECT_LABEL: 'View more at myDXC',
  LABEL_GO_TO_ACTION: 'Go to action',
  TOOLTIP_MESSAGE: 'View the latest news from DXC',
  POLL_INTERVAL: HUB_DEFAULT_CONSTS.POLL_INTERVAL * 6 // 1 hour in milliseconds
};

// Global Function
export const GLOBAL_FUNCTIONS_COMPONENT_CONSTS = {
  TITLE: 'Corporate Departments',
  ITEMS_STARTING_POINT: 0,
  MAX_ITEMS: 16,
  SELECT_DEPARTMENT: 'Department',
  SELECT_REGION: 'Region',
  TOOLTIP_MESSAGE: 'Official myDXC pages for corporate departments and regions',
  DATA_LIST_TITLE_REGION: 'REGION_LIST', // Get the title text from the GlobalFunctions.json file
  DATA_LIST_TITLE_DEPARTMENT: 'DEPARTMENT_LIST' // Get the title text from the GlobalFunctions.json file
};

// World Clock Component
export const WORLD_CLOCK_COMPONENT_CONSTS = {
  ...DIALOG_MEASUREMENTS_CONSTS,
  STATUS_SUCCESS: 'success',
  STATUS_ERROR: 'error',
  REASON_SUCCESS_RETRIEVED: 'Successfully retrieved clock settings.',
  REASON_SUCCESS_SAVED: 'Successfully saved clock settings.',
  REASON_ERROR_NOT_RETRIEVED: 'Error retrieving clock settings.',
  REASON_ERROR_NOT_SAVED: 'Error saving clock settings.',
  DEFAULT_DATE_FORMAT: 'MMM d, yyyy', // Format: Jan 1, 1970
  DEFAULT_TIME_FORMAT: 'h:mm a', // Format: 1:23 AM
  DEFAULT_SETTINGS: {
    worldclock: {
      dateFormat: 'MMM d, yyyy',
      timeFormat: 'h:mm a',
      favorites: [],
      recent: [],
      current: null,
      showDayOfWeek: true
    }
  },
  ACTION_CLICK: 'click',
  ACTION_CHANGE: 'change',
  MESSAGE_CLICK: 'Opened clock settings.',
  MESSAGE_CHANGE: 'Changed clock settings.',
  LOCATION_LOCAL: 'Local',
  LABEL_LOCAL_TIME: 'Your local time',
  DAYOFWEEK_FORMAT: 'EEEE, ',
  DATE_INTERVAL: HUB_DEFAULT_CONSTS.DATE_INTERVAL,
  STATUS_TIMEOUT: HUB_DEFAULT_CONSTS.COMPONENT_TIMEOUT,
  MENU_TIMEOUT: HUB_DEFAULT_CONSTS.MENU_TIMEOUT
};

// Clock Dialog Component
export const CLOCK_DIALOG_COMPONENT_CONSTS = {
  TITLE: 'Settings',
  ACTION_EDIT_FAVORITES: 'Edit Favorites',
  ACTION_EDIT_FORMAT: 'Edit Date/Time Format',
  BUTTON_SAVE: 'SAVE'
};

// Clock Favorites Tab Component
export const CLOCK_FAVORITES_TAB_COMPONENT_CONSTS = {
  CLOCK_SEARCH_OPTION_LIST_ITEM_HEIGHT: 48, // mat-option item default height
  CLOCK_SEARCH_MAX_OPTION_LIST_ITEMS: 5,
  CLOCK_SETTINGS_MAX_RECENT_SEARCH_LENGTH: 5,
  CLOCK_SETTINGS_MAX_FAVORITES_LENGTH: 15,
  CLOCK_SETTINGS_ERROR_MAX_FAVORITES_REACHED: 'Max number of favorites reached; cannot add more than 15 favorites.',
  LABEL_EDIT_FAVORITES: 'Add, edit, or delete your favorites list.',
  LABEL_NO_FAVORITES: 'No favorites',
  PLACEHOLDER_SEARCH: 'Search by country or city to add to favorites',
  DATE_INTERVAL: HUB_DEFAULT_CONSTS.DATE_INTERVAL,
  STATUS_TIMEOUT: HUB_DEFAULT_CONSTS.COMPONENT_TIMEOUT
};

// Clock Settings Tab Component
export const CLOCK_SETTINGS_TAB_COMPONENT_CONSTS = {
  LABEL_CUSTOMIZE: 'Customize how the time and date of your favorite location is displayed.',
  LABEL_SAMPLE: 'Example Time and Date:',
  LABEL_DATE_FORMAT: 'Date Format',
  LABEL_TIME_FORMAT: 'Time Format',
  LABEL_SHOW_DAY_OF_WEEK: 'Display Day of Week',
  DAYOFWEEK_FORMAT: 'EEEE, ',
  DATE_FORMAT_MMM_D_YYYY: 'mmm d, yyyy',
  DATE_FORMAT_MM_DD_YYYY: 'mm/dd/yyyy',
  DATE_FORMAT_DD_MM_YYYY: 'dd/mm/yyyy',
  DATE_FORMAT_YYYY_MM_DD: 'yyyy/mm/dd',
  TIME_FORMAT_24HOUR: '24 hour',
  TIME_FORMAT_AM_PM: 'AM/PM'
};

// Hub component
export const HUB_COMPONENT_CONSTS = {
  ...FOOTER_COMPONENT_CONSTS,
  GREETING_SUBSCRIPTION_INTERVAL: HUB_DEFAULT_CONSTS.DATE_INTERVAL, // 1 second
  CONFIG_INTERVAL: HUB_DEFAULT_CONSTS.DATE_INTERVAL * 5, // 5 seconds
  TOOLS_SECTION_TIMEOUT: HUB_DEFAULT_CONSTS.DATE_INTERVAL, // 1 second
  VALUE_DXCHUB: HUB_DEFAULT_CONSTS.VALUE_DXCHUB,
  VALUE_MYHUB: HUB_DEFAULT_CONSTS.VALUE_MYHUB,
  GREETING_MORNING: 'Good Morning',
  GREETING_AFTERNOON: 'Good Afternoon',
  GREETING_EVENING: 'Good Evening',
  NAME_REGEX: /(\w)(\w*)/g,
  TITLE_NOTIFICATIONS: 'Notifications',
  LABEL_BUTTON_DXCHUB: 'DXC HUB',
  LABEL_BUTTON_MYHUB: 'MY HUB',
  FAVORITES_SECTION_TRANSITION_TIMEOUT: FAVORITE_SNACKBAR_COMPONENT_CONSTS.FAVORITES_SECTION_TIMEOUT,
  OFFSET_TIMEOUT: FAVORITE_SNACKBAR_COMPONENT_CONSTS.OFFSET_TIMEOUT,
  HUB_WORK_PROFILE_TRANSITION_TIMEOUT: 1500
};

// Trending component
export const TRENDING_COMPONENT_CONSTS = {
  NUMBER_OF_LINKS: 4,
  TITLE: 'Trending',
  HEADER_TRENDING: 'Trending information and articles for you.',
  NAV_MENU_OPTION: [
    {
      TITLE: 'Documents',
      INDEX: 0,
      TOOLTIP_MESSAGE: 'Documents that are trending in your part of the organization and to which you have access.'
    },
    {
      TITLE: 'Employee Connect',
      INDEX: 1,
      TOOLTIP_MESSAGE: 'Trending searches in Employee Connect knowledge articles.'
    },
    {
      TITLE: 'IT Support',
      INDEX: 2,
      TOOLTIP_MESSAGE: 'Trending searches in IT Support knowledge articles.'
    }
  ]
};

// Trending HRConnect component
export const TRENDING_HRCONNECT_COMPONENT_CONSTS = {
  ARTICLE_INFO: {
    CATEGORY: 'Knowledge Base Category: ',
    TOPIC_CATEGORY: 'Category: ',
    KB: 'Knowledge Base: ',
    TOPIC: 'Topic: ',
    COUNTRY: 'Country: ',
    LANGUAGE: 'Language: ',
    RATING: 'Rating: '
  },
  LABEL_SORT: 'Sort: ',
  LABEL_FILTER: 'Filter: ',
  LINK_HRCONNECT: 'https://dxchr.service-now.com/hrportal',
  FOOTER_TEXT: "Can't find what you're looking for? Go to Employee Connect",
  GLOBAL_OPTION: 'Global',
  ALL_OPTION: 'All',
  LABEL_ALL_ARTICLES: 'All Articles',
  LABEL_SORT_MOST: 'Most Viewed',
  LABEL_SORT_NEW: 'Newest',
  LABEL_SORT_LATEST: 'Last Updated',
  LABEL_STAR: ' star',
  LABEL_STARS: ' stars',
  ONE_RATING: 1,
  ONE_KB: 1,
  MAX_ARTICLE_COUNT: 10,
  HR_CONNECT_GLOBAL: 'global',
  PLACEHOLDER_FILTER_OPTIONS: {
    CATEGORY: 'Category',
    TOPIC: 'Topic',
    COUNTRY: 'Country',
    KB: 'Knowledge Base',
    RATING: 'Rating'
  },
  RESET_FILTERS: 'Reset All',
  COUNTRIES_ISO_LOOKUP: {
    KR: 'Korea',
    RU: 'Russian Federation',
    US: 'United States Of America',
    VN: 'Viet Nam'
  },
  DEBOUNCE_TIME: 500,
  NO_RESULTS: 'No results for current selection'
};

// Trending ITConnect component
export const TRENDING_ITCONNECT_COMPONENT_CONSTS = {
  ARTICLE_INFO: {
    LAST_UPDATED: 'Last Updated: ',
    RATING: TRENDING_HRCONNECT_COMPONENT_CONSTS.ARTICLE_INFO.RATING,
    VIEWS: 'Views: ',
    CATEGORY: TRENDING_HRCONNECT_COMPONENT_CONSTS.ARTICLE_INFO.CATEGORY,
    KB: TRENDING_HRCONNECT_COMPONENT_CONSTS.ARTICLE_INFO.KB
  },
  LABEL_SORT: TRENDING_HRCONNECT_COMPONENT_CONSTS.LABEL_SORT,
  LABEL_FILTER: TRENDING_HRCONNECT_COMPONENT_CONSTS.LABEL_FILTER,
  LINK_ITCONNECT: 'http://help.dxc.com/',
  FOOTER_TEXT: "Can't find what you're looking for? Go to IT Support",
  ALL_OPTION: TRENDING_HRCONNECT_COMPONENT_CONSTS.ALL_OPTION,
  LABEL_SORT_MOST: TRENDING_HRCONNECT_COMPONENT_CONSTS.LABEL_SORT_MOST,
  LABEL_SORT_NEW: TRENDING_HRCONNECT_COMPONENT_CONSTS.LABEL_SORT_NEW,
  LABEL_SORT_LATEST: TRENDING_HRCONNECT_COMPONENT_CONSTS.LABEL_SORT_LATEST,
  LABEL_STAR: TRENDING_HRCONNECT_COMPONENT_CONSTS.LABEL_STAR,
  LABEL_STARS: TRENDING_HRCONNECT_COMPONENT_CONSTS.LABEL_STARS,
  ONE_RATING: TRENDING_HRCONNECT_COMPONENT_CONSTS.ONE_RATING,
  ONE_KB: TRENDING_HRCONNECT_COMPONENT_CONSTS.ONE_KB,
  MAX_ARTICLE_COUNT: TRENDING_HRCONNECT_COMPONENT_CONSTS.MAX_ARTICLE_COUNT,
  PLACEHOLDER_FILTER_OPTIONS: {
    CATEGORY: TRENDING_HRCONNECT_COMPONENT_CONSTS.PLACEHOLDER_FILTER_OPTIONS.CATEGORY,
    KB: TRENDING_HRCONNECT_COMPONENT_CONSTS.PLACEHOLDER_FILTER_OPTIONS.KB,
    RATING: TRENDING_HRCONNECT_COMPONENT_CONSTS.PLACEHOLDER_FILTER_OPTIONS.RATING
  },
  RESET_FILTERS: TRENDING_HRCONNECT_COMPONENT_CONSTS.RESET_FILTERS,
  NO_RESULTS: TRENDING_HRCONNECT_COMPONENT_CONSTS.NO_RESULTS
};

// Tools and resources component
export const TOOLS_RESOURCES_COMPONENT_CONSTS = {
  TITLE: 'Tools and Resources',
  SNACKBAR_HORIZONTAL_POS: 'right',
  SNACKBAR_VERTICAL_POS: 'bottom',
  SNACKBAR_DURATION: HUB_DEFAULT_CONSTS.COMPONENT_TIMEOUT,
  PLACEHOLDER_SEARCH: 'Search',
  ACTION_GO: 'Go to',
  ACTION_UNFAVORITE: 'Unfavorite',
  ACTION_FAVORITE: 'Favorite',
  TOOLTIP_MESSAGE: 'List of DXC internal resources, apps, and sites. Search for a tool using phrases such as "W2." ',
  LABEL_REQUEST_TOOLS: 'Request New/Update Tool or Resource',
  LINK_REQUEST_TOOLS: 'https://dxcportal.sharepoint.com/sites/dxchubhelp/SitePages/New-Tool-or-Resource-Request.aspx',
  COPY_SNACKBAR_DURATION: 1000,
  MESSAGE_COPY_LINK: 'Link copied'
};

// Recent shared files component
export const RECENT_SHARED_FILES_COMPONENT_CONSTS = {
  TITLE: 'Recommended Documents',
  NUMBER_OF_LINKS: 5,
  MESSAGE_COPY_LINK: TRENDING_DOCS_COMPONENT_CONSTS.MESSAGE_COPY_LINK,
  LABEL_EDITED: 'edited this',
  LABEL_SHARED: 'shared',
  LABEL_INSIGHTS: 'insights',
  LABEL_YOU: 'You',
  TOOLTIP_MESSAGE: 'View documents you recently interacted with and would like to access again',
  ACTION_COPY_LINK: TRENDING_DOCS_COMPONENT_CONSTS.LABEL_COPY,
  ACTION_FAVORITE: 'Favorite',
  ACTION_SHARE: 'Share',
  ACTION_OPEN: TRENDING_DOCS_COMPONENT_CONSTS.LABEL_OPEN,
  SNACKBAR_DURATION: TRENDING_DOCS_COMPONENT_CONSTS.SNACKBAR_DURATION
};

// Custom Tools and Resources Component
export const CUSTOM_TOOLS_RESOURCE_CONTROL_COMPONENT_CONSTS = {
  PLACEHOLDER_SEARCH: 'Search'
};

// Top Navigation Component
export const TOP_NAV_COMPONENT_CONSTS = {
  SECTION_DXC_LOGO_HOVER: 'Click to go to DXC Hub page',
  SECTION_FAVORITES: 'Favorites',
  SECTION_WORK_PROFILE: 'View Profile',
  PROFILE_PREFERRED_NAME: 'Preferred Name',
  PROFILE_DROPDOWN_HOME: 'Home',
  PROFILE_DROPDOWN_SETTINGS: 'Settings',
  PROFILE_DROPDOWN_FAV: 'Favorites',
  PROFILE_DROPDOWN_ADMIN: 'Admin',
  PROFILE_DROPDOWN_ANNOUNCEMENTS: 'Announcements',
  PROFILE_DIALOG_HEIGHT: '80vh',
  TIMEOUT: HUB_DEFAULT_CONSTS.MENU_TIMEOUT,
  NAME_REGEX: /(\w)(\w*)/g,
  HELP_LINK: 'https://dxcportal.sharepoint.com/sites/dxchubhelp',
  HELP_LABEL: 'Help',
  ADD_ADMIN_LINK: 'admin',
  MAX_NOTIF_COUNT: 99,
  PAGE_TOP_OFFSET_POSITION: 0,
  LOGOUT_LABEL: 'Logout'
};

// View user profile commponenet
export const VIEW_USER_PROFILE_COMPONENT_CONSTS = {
  TITLE: 'Profile',
  LABEL_PREFERRED_NAME: 'Preferred Name',
  LABEL_ROLE_TYPE: 'Role Type',
  LABEL_FIRST_NAME: 'First Name',
  LABEL_LAST_NAME: 'Last Name',
  LABEL_EMP_ID: 'Employee ID',
  LABEL_EMP_TYPE: 'Employee Type',
  LABEL_EMAIL: 'Email',
  LABEL_SHORT_NAME: 'Short Name',
  LABEL_JOB_TITLE: 'Job Title',
  LABEL_JOB_FAMILY: 'Job Family',
  LABEL_MANAGER_FULL_NAME: 'Manager Full Name',
  LABEL_MANAGER_ID: 'Manager Employee ID',
  LABEL_MANAGER_EMAIL: 'Manager Email',
  LABEL_MANAGER_SHORT_NAME: 'Manager Short Name',
  LABEL_CENTER_NAME: 'Cost Center',
  LABEL_BUSINESS_UNIT: 'Business Unit/Capability',
  LABEL_COUNTRY: 'Country',
  LABEL_REGION: 'Region',
  LABEL_TIMEZONE: 'Timezone',
  LABEL_LOCATION: 'Location',
  MENU_WORKER_DETAILS: 'Worker Details',
  MENU_MANAGER_DETAILS: 'Manager Details',
  MENU_LOCATION_DETAILS: 'Location Details',
  MENU_MY_PROFILES: 'My Profiles',
  ITCONNECT: 'ITConnect',
  UPTIME: 'UPtime',
  NAME_REGEX: /(\w)(\w*)/g,
  LABEL_EDIT: 'EDIT',
  LABEL_NONE: '-',
  EDIT_LINK: 'https://dxchr.service-now.com/hrportal?id=kb_article_view&sysparm_article=KB0017968'
};

// Notifications Component
export const NOTIFICATIONS_COMPONENT_CONSTS = {
  APP_INSIGHTS_LOC: 'Notification menu',
  NOTIFICATION_LABEL: 'Notifications',
  NO_NOTIFICATIONS: 'You currently have no notifications or alerts',
  LABEL_TODAY: 'Today',
  LABEL_TAB_ALL: 'All',
  LABEL_TAB_NOTIFICATIONS: 'Notifications',
  LABEL_TAB_TASKS: 'Tasks',
  LABEL_TAB_ALERTS: 'Alerts',
  LABEL_TAB_UAMNOTIFICATIONS: 'UAM Notifications',
  NOTIF_PANE_STATIC_HEIGHT: 150,
  NOTIF_PANE_TIMEOUT: HUB_DEFAULT_CONSTS.COMPONENT_TIMEOUT * 2, // 10 seconds
  NOTIF_UPDATE_HEIGHT_TIMEOUT: 500
};

// Notification Card Component
export const NOTIFICATION_CARD_COMPONENT_CONSTS = {
  ANNOUNCEMENT_FROM: 'Announcement from',
  LABEL_ACTION_NEEDED: 'Action needed',
  TIMESTAMP_FORMAT: "d LLL YYYY 'at' h:mm a",
  NOTIF_DXC_HUB: 'DXC HUB',
  NOTIF_DXC_TIME: 'DXC Time',
  NOTIF_UAM: 'UAM'
};

export const ICON_SERVICES_CONSTS = {
  ASSETS_ICONS_LOCATION: 'assets/icons',
  SVG_EXT: '.svg'
};

export const ICON_GLOBAL_FUNCTION_CONSTS = {
  ASSETS_GLOBAL_FUNCTION_LOCATION: 'assets/icons/global-functions',
  SVG_EXT: '.svg'
};

export const ICON_REGION_CONSTS = {
  ASSETS_REGION_LOCATION: 'assets/icons/region-workplace/region',
  SVG_EXT: '.jpeg'
};

export const ICON_WORKPLACE_CONSTS = {
  ASSETS_WORKPLACE_LOCATION: 'assets/icons/region-workplace/workplace',
  SVG_EXT: '.JPG'
};

// Custom Tools and Resources Component
export const MOBILE_WARNING_CONSTS = {
  WARNING_MESSAGE:
    'DXC HUB works only in Microsoft EDGE browser both on Android and iOS devices. <br/><br/> Please ensure that you have installed and set up Microsoft Authenticator (download from the App or Play Store) with your DXC account. <br/><br/> DXC HUB is not accessible on the Microsoft Teams mobile app.',
  DETECT_DEVICE: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
};

// Announcements Component
export const ANNOUNCEMENTS_COMPONENT_CONSTS_SHARED = {
  PERMISSIONLEVEL: {
    DIRECTREPORTS: {
      value: 'directReports',
      lable: 'Direct reports'
    },
    REGION: {
      value: 'region',
      lable: 'Region'
    },
    DEPARTMENT: {
      value: 'department',
      lable: 'Department'
    },
    ALLDXC: {
      value: 'allDXC',
      lable: 'All DXC'
    },
    MANAGERS: {
      value: 'Managers',
      lable: 'Managers'
    }
  }
};

export const ANNOUNCEMENTS_COMPONENT_CONSTS = {
  ...ANNOUNCEMENTS_COMPONENT_CONSTS_SHARED,
  COUNT_OF_ANNOUNCEMENTS_LIST: 5,
  TITLE_MAIN: 'Announcements',
  SUCCESS: 'SUCCESS',
  SUCCESS_MESSAGE: '- Your announcement was succesfully sent',
  STATUS_ACTIVE: 'Active',
  STATUS_SCHEDULED: 'Scheduled',
  STATUS_INACTIVE: 'Expired',
  FROM: 'From',
  TILL: 'Till',
  RECIPIENTS: 'Recipients',
  BUTTON_CREATE_ANNOUNCEMENT: 'CREATE ANNOUNCEMENT',
  LABEL_NO_ANNOUNCEMENTS: "You don't have active announcements"
};

// add announcement Component
export const ADD_ANNOUNCEMENT_COMPONENT_CONSTS = {
  ...ANNOUNCEMENTS_COMPONENT_CONSTS_SHARED,
  GAP_BETWEEN_MINDATE_TO_MAXDATE: 30 * 24 * 3600 * 1000,
  TITLE_MAIN: 'Create announcement',
  LABLE_RECIPIENTS: 'Recipients',
  PLACEHOLDER_RECIPIENTS: 'Select recipient',
  PERMISSIONLEVEL_DIRECTREPORTS_LABLE: '',
  LABLE_DATE_RANGE: 'Date range',
  LABLE_FROM: 'From',
  LABLE_TILL: 'Till',
  LABLE_ANNOUNCEMENT_TEXT: 'Announcement text',
  PLACEHOLDER_ANNOUNCEMENT_TEXT: 'Placeholder',
  LABLE_LINK: 'Link',
  PLACEHOLDER_LINK: 'URL address',
  LABLE_TICK_TOP_BANNER: 'Display announcement as a top banner',
  MESSAGE_TICK_TOP_BANNER:
    'Announcement will be displayed as a banner at the top of the DXC Hub instead of Alerts & Notifications sidebar',
  LABEL_REQUIRED: 'Required',
  LABEL_EDIT_URL_INVALID: 'URL format is invalid',
  BUTTON_CANCEL: 'CANCEL',
  BUTTON_SAVE: 'Send announcement',
  LABLE_DATE_RANGE_INVALID: 'Date range is not valid'
};

// add announcement Component
export const EDIT_ANNOUNCEMENT_COMPONENT_CONSTS = {
  ...ADD_ANNOUNCEMENT_COMPONENT_CONSTS,
  TITLE_MAIN: 'Edit announcement'
};

/*------------------------------------------------------------*/
/*--------------------------SERVICES--------------------------*/
/*------------------------------------------------------------*/

// App Insights Service
export const APP_INSIGHTS_SERVICE_CONSTS = {
  QUERY_SEARCH_PARAMETERS: 'searched for: office search ',
  QUERY_TIMESPAN_PARAMETERS: 'P90D',
  QUERY_FILTER_PARAMETERS: 'customDimensions/searchTerm ne null',
  QUERY_SELECT_PARAMETERS: 'customDimensions/searchTerm',
  QUERY_TOP_PARAMETERS: '5',
  SEARCH_KEYWORD_CLICK: 'click',
  SEARCH_KEYWORD_SEARCH: 'search:'
};

// Calendar Service
export const CALENDAR_EVENTS_SERVICE_CONSTS = {
  QUERY_ORDER_BY: 'start/dateTime',
  HEADER_CONSISTENCY_LEVEL: 'eventual',
  QUERY_SCHEDULE_TIMEZONE: 'Pacific Standard Time',
  QUERY_SCHEDULE_START_DATETIME: '2022-01-18T09:00:00',
  QUERY_SCHEDULE_END_DATETIME: '2022-01-18T10:00:00'
};

// Enterprise Search Service
export const ENTERPRISE_SEARCH_SERVICE_CONSTS = {
  QUERY_SELECT_USED_FILE: ['name', 'webUrl', 'lastModifiedDateTime'].join(','),
  QUERY_SHARED_INSIGHTS_FILTER_LASTSHARED_SHAREDBY_ADDRESS: 'lastShared/sharedBy/address ne',
  QUERY_TRENDING_FILTER_RESOURCEVISUALIZATION_TYPE: "resourceVisualization/type ne 'spsite'",
  QUERY_TRENDING_SP_FILTER_RESOURCEVISUALIZATION_TYPE: "resourceVisualization/type eq 'spsite'",
  QUERY_INSIGHTS_TOP: '10',
  QUERY_GET_THUMBNAIL_SELECT: 'medium',
  ICON_EDIT_FILE: 'editfile',
  ITEM_TYPE_INSIGHTS: 'Insights',
  ITEM_TYPE: 'insights',
  ICON_SHARED_FILE: 'sharedfile',
  SHARED_TYPE: 'shared',
  FILE_EXT_MIN_LENGTH: 3,
  SITE_ICON_NAME_NA: 'NA',
  GRAPH_API_INSIGHT_RESOURCE_TYPE_FILE_EXTENSION_MAPPING: {
    powerpoint: 'pptx',
    word: 'docx',
    excel: 'xlsx',
    csv: 'csv',
    pdf: 'pdf',
    onenotepage: 'one',
    onenote: 'one',
    image: 'photo',
    video: 'video',
    text: 'txt',
    mail: 'outlook',
    project: 'project',
    spsite: 'sharepoint',
    visio: 'visio',
    publisher: 'pub',
    access: 'access',
    archive: 'zip',
    folder: 'folder',
    xml: 'xml',
    web: 'web'
  },
  CONTENT_SOURCE_SEARCH_REQUESTDATA_ENTITYTYPE: ['externalItem'],
  CONTENT_SOURCE_SEARCH_REQUESTDATA_FIELDS: [
    'SysID',
    'accessURL',
    'articleid',
    'articletype',
    'category',
    'kbcategory',
    'kbknowledgebase',
    'language',
    'number',
    'PreviewContent',
    'rating',
    'resultsnippet',
    'shortdescription',
    'syscreatedby',
    'syscreatedon',
    'sysid',
    'systags',
    'sysupdatedby',
    'sysupdatedon',
    'sysviewcount',
    'topic',
    'ucountry',
    'usecount',
    'version',
    'workflowstate',
    'author'
  ],
  CONTENT_SOURCE_SEARCH_REQUESTDATA_HEADERS: { 'Content-Type': 'application/json' }
};

// Mail service
export const MAIL_SERVICE_CONSTS = {
  HEADERS: { 'Content-Type': 'application/json' }
};

// People Service
export const PEOPLE_SERVICE_CONSTS = {
  USERNAME_CHECKER_REGEX: /^([^,\s]+)\s([^,\s]+)$/,
  QUERY_EXPAND_MANAGER: 'manager',
  QUERY_SELECT_USER_FIELDS: [
    'businessPhones',
    'displayName',
    'givenName',
    'jobTitle',
    'mail',
    'mobilePhone',
    'officeLocation',
    'preferredLanguage',
    'surname',
    'userPrincipalName',
    'id',
    'employeeId',
    'department',
    'companyName',
    'mailNickname',
    'country',
    'usageLocation',
    'userType',
    'state',
    'extension_36cbd5e970e34e26877bee2908b7dceb_employeeType'
  ].join(','),
  HEADER_CONSISTENCY_LEVEL: 'eventual',
  QUERY_EMPLOYEE_TYPE: 'extension_36cbd5e970e34e26877bee2908b7dceb_employeeType',
  QUERY_USERTYPE_MEMBER: 'Member'
};

// Recent Search Service
export const RECENT_SEARCH_SERVICE_CONSTS = {
  MAXIMUM_RECENT_SEARCH_LENGTH: 5
};

// Notification Service
export const NOTIFICATION_SERVICE_CONSTS = {
  POLL_INTERVAL: HUB_DEFAULT_CONSTS.POLL_INTERVAL
};

// Notifications - Approval Central Service
export const APPROVAL_CENTRAL_NOTIFICATIONS_SERVICE_CONSTS = {
  TITLE: 'Approval Central',
  LINK: environment.DEPS.APPROVAL_CENTRAL.APP_URL,
  MESSAGE: function (count: number): string {
    return `You have ${count} task${count > 1 ? 's' : ''} in Approval Central.`;
  },
  QUERY_STATUS: 'pending'
};

// Notifications - DXC Time Service
export const DXCTIME_NOTIFICATION_SERVICE_CONSTS = {
  TITLE: NOTIFICATION_CARD_COMPONENT_CONSTS.NOTIF_DXC_TIME,
  LINK: environment.DEPS.DXCTIME.URL,
  MESSAGE: function (count: number): string {
    return `You have ${count} overdue timesheet${count > 1 ? 's' : ''}. Go to DXC Time to submit your timesheets.`;
  }
};

// Notifications - Workday Service
export const WORKDAY_NOTIFICATION_SERVICE_CONSTS = {
  TITLE: 'Workday',
  LINK: environment.DEPS.WORKDAY.URL,
  MESSAGE: function (count: number): string {
    return `You have ${count} notification${count > 1 ? 's' : ''} in Workday.`;
  }
};

// Notifications - UAM Service
export const UAM_NOTIFICATION_SERVICE_CONSTS = {
  TITLE: NOTIFICATION_CARD_COMPONENT_CONSTS.NOTIF_UAM,
  LINK: 'https://accessmanager.dxc.com/uam/default.aspx',
  MESSAGE: function (notifType: string, count: number): string {
    return `You have ${count} ${count > 1 ? notifType : notifType.slice(0, -1)} in UAM.`;
  },
  NOTIF_TYPE: {
    APPROVALS: 'approvals',
    RENEWALS: 'renewals',
    SUBMISSIONS: 'submissions'
  }
};

// Notifications - dxchub Service
export const DXCHUB_NOTIFICATION_SERVICE_CONSTS = {
  TITLE: 'DXC HUB'
};

// Search Data Service
export const SEARCHDATA_SERVICE_CONSTS = {
  DATE_FORMAT: 'MM/DD/YYYY',
  LABEL_REPUBLIC_OF: 'Republic of',
  GLOBAL: 'global',
  COUNTRY_CHECKER_REGEX: /\d/,
  HR_ARTICLE_LINK: 'https://dxchr.service-now.com/hrportal/?id=kb_article&sys_id=',
  ITCONNECT_KB_URL: function (sys_kb_id: string): string {
    return `https://uptime.dxc.com/knowledges/detail/${sys_kb_id}`;
  }
};

// News Service
export const NEWS_SERVICE_CONSTS = {
  MAX_CONTENT_LENGTH: 5
};

/*------------------------------------------------------------*/
/*-------------------------DIRECTIVES-------------------------*/
/*------------------------------------------------------------*/

// Tooltip directive
export const TOOLTIP_DIRECTIVE_CONSTS = {
  MIN_SCREEN_WIDTH: 150,
  MAX_SCREEN_WIDTH: 1200,
  POSITION_OFFSET: 10,
  EVENTS: {
    SHOW: ['mouseenter', 'mousedown', 'click'],
    HIDE: ['mouseleave', 'mousedown', 'wheel', 'scroll']
  }
};

// Input Clear directive
export const INPUT_CLEAR_DIRECTIVE_CONSTS = {
  EVENT_DEBOUNCE_TIME: 100
};

//Add Permission dialog (Popup) component
export const ANNOUNCEMENT_PERMISSION_DIALOG_COMPONENT_CONSTS = {
  TITLE: 'Add Announcements Permission',
  BUTTON_ADD_PERMISSION: 'ADD PERMISSION',
  ACTION_CANCEL: 'CANCEL',
  LABEL_LEVEL: 'Permission Level',
  LEVEL_DIRECT_REPORTS: 'Direct Reports',
  LEVEL_DEPT: 'Department',
  LEVEL_REGION: 'Region',
  LEVEL_ALL: 'All DXC',
  ALLOW_TOP_BANNER_LABEL: 'Allow to create top banner announcments',
  EMP_NAME: 'Employee Name',
  SELECT_EMP_LABEL: 'Select employee by name or email',
  SELECT_EMP: 'Select employee',
  PERMISSION_LEVEL: 'Permission_Level',
  DIRECT_REPORTS: 'Direct Reports',
  DIRECT_REPORTS_HELP: 'Announcements will be sent to employees direct reports only',
  DEPARTMENT_LABEL: 'Department',
  DEPARTMENT_HELP: 'Announcements will be sent to employees specified department only',
  REGION_LABEL: 'Region',
  REGION_HELP: 'Announcements will be sent to employees specified region',
  ALL_DXC: 'All DXC',
  ALL_DXC_HELP: 'Announcements will be sent to All DXC employees',
  ALLOW_TOP_BANNER_HELP: 'Grant user a permission to create top banner announcements',
  CANCEL_LINK: 'CANCEL',
  PLACEHOLDER_SEARCH_ADD_PERMISSION: 'Select Employee',
  NAME_REGEX: /(\w)(\w*)/g,
  ERROR_CUSTOM_PERMISSION_REQUIRED: '* Grant atleast one permission level to continue',
  ERROR_ADD_PERMISSION_USER_REQUIRED: 'Select an employee to add the permission levels',
  ERROR_SEARCH_EMPLOYEE_TO_ADD_PERMISSION: '* Search for an employee to add the permission',
  ERROR_VALID_SHORTNAME_EMAIL: '* Search the employee by a valid Shortname or Email Id',
  ERROR_REGION_DEPARTMENT_REQUIRED: '* Department and Region name should be required to grant the permission',
  DEPARTMENT_NAME_LABEL: 'Department',
  REGION_NAME_LABEL: 'Region',
  EMAIL_REGEX: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
  MESSAGE_PERMISSION_ALREADY_EXISTS: '* This user already has permission to add announcements'
};

export const EDIT_ANNOUNCEMENT_PERMISSION_DIALOG_COMPONENT_CONSTS = {
  TITLE: 'Edit Announcements Permission',
  BUTTON_EDIT_PERMISSION: 'SUBMIT CHANGES',
  ACTION_CANCEL: 'CANCEL',
  LABEL_LEVEL: 'Permission Level',
  LEVEL_DIRECT_REPORTS: 'Direct Reports',
  LEVEL_DEPT: 'Department',
  LEVEL_REGION: 'Region',
  LEVEL_ALL: 'All DXC',
  ALLOW_TOP_BANNER_LABEL: 'Allow to create top banner announcments',
  EMP_NAME: 'Employee Name',
  SELECT_EMP_LABEL: 'Select employee by name or email',
  SELECT_EMP: 'Select employee',
  PERMISSION_LEVEL: 'Permission_Level',
  DIRECT_REPORTS: 'Direct Reports',
  DIRECT_REPORTS_HELP: 'Announcements will be sent to employees direct reports only',
  DEPARTMENT_LABEL: 'Department',
  DEPARTMENT_HELP: 'Announcements will be sent to employees specified department only',
  REGION_LABEL: 'Region',
  REGION_HELP: 'Announcements will be sent to employees specified region',
  ALL_DXC: 'All DXC',
  ALL_DXC_HELP: 'Announcements will be sent to All DXC employees',
  ALLOW_TOP_BANNER_HELP: 'Grant user a permission to create top banner announcements',
  DEPARTMENT_NAME_LABEL: 'Department',
  REGION_NAME_LABEL: 'Region',
  CANCEL_LINK: 'CANCEL',
  ERROR_CUSTOM_PERMISSION_REQUIRED: '* Grant atleast one permission level to continue',
  ERROR_REGION_DEPARTMENT_REQUIRED: '* Department or Region name is empty for selected employee',
  REMOVE_PERMISSION: 'REMOVE PERMISSIONS'
};
// Add Announcement Permissions component
export const ADD_PERMISSION_COMPONENT_CONSTS = {
  ...PEOPLE_SEARCH_COMPONENT_CONSTS,
  ...TOP_NAV_COMPONENT_CONSTS,
  PERMISSION_SEARCH_PAGINATION_MIN_ITEMS_PER_PAGE: 10,
  PERMISSION_SEARCH_PAGINATION_ITEMS_PER_PAGE: [5, 10, 15],
  PERMISSION_SEARCH_FILTER_ALL: 'All',
  PERMISSION_SEARCH_RESULTS_COLUMNS: ['name', 'perm_level', 'topbanner_announcement', 'Actions'],
  TITLE: 'Add Permission',
  PLACEHOLDER_SEARCH_ADD_PERMISSION: 'Search by Name, Email ID',
  NAME: 'NAME',
  PERMISSION_LEVEL: 'Permission Level',
  LINK_EDIT_PERMISSIONS: 'Edit Permissions',
  TOP_BANNER_PERMISSION: 'TOP BANNER PERMISSION',
  ACTIONS: 'Actions',
  EDIT_SUCCESS_MESSAGE: 'Permission has been updated succesfully for the selected employee',
  ADD_SUCCESS_MESSAGE: 'Permission has been added succesfully for the selected employee',
  REMOVE_SUCCESS_MESSAGE: 'Permission for the selected employee is successfully removed',
  SUCCESS_WORD: 'SUCCESS:'
};

// Admin Global Functions component
export const ADMIN_GLOBAL_FUNCTIONS_CONSTS = {
  ...TOP_NAV_COMPONENT_CONSTS,
  ADD_TITLE: 'Add Global Functions',
  EDIT_TITLE: 'Edit Global Functions',
  BUTTON_ADD: 'SAVE',
  BUTTON_EDIT: 'SAVE',
  ACTION_CANCEL: 'CANCEL',
  PLACEHOLDER_SEARCH_GLOBAL_FUCTIONS_ADMIN: 'Search by Tittle',
  SEARCH_RESULTS_COLUMNS: ['icon', 'type', 'title', 'link', 'index', 'actions'],
  HEADER_TYPE: 'TYPE',
  HEADER_TITLE: 'TITLE',
  HEADER_LINK: 'LINK',
  HEADER_ACTIONS: 'ACTIONS',
  SELECT_DEPARTMENT: 'Department',
  SELECT_REGION: 'Region',
  INDEX: 'INDEX',
  ICON: 'ICON',
  ICONS: [
    'hub_gl_computer_check',
    'hub_gl_cycle_people',
    'hub_gl_ethics_and_compliance',
    'hub_gl_finance_gavel',
    'hub_gl_global_city',
    'hub_gl_global_nav',
    'hub_gl_handshake',
    'hub_gl_health_people',
    'hub_gl_home',
    'hub_gl_message_bubbles',
    'hub_gl_money',
    'hub_gl_people_hand',
    'hub_gl_survey_checkbox',
    'hub_gl_website_hand'
  ],
  PLACEHOLDER_ADD_GLOBAL_FUCTIONS_ADMIN_TITLE: 'Global function title',
  PLACEHOLDER_ADD_GLOBAL_FUCTIONS_ADMIN_URL: 'URL address',
  LABEL_REQUIRED: 'Required',
  LABEL_INVALID_URL: 'URL format is invalid',
  ADD_SUCCESS_MESSAGE: 'Global function has been added successfully.',
  UPDATE_SUCCESS_MESSAGE: 'Global function has been updated successfully.',
  DELETE_SUCCESS_MESSAGE: 'Global function has been removed successfully.',
  SUCCESS_WORD: 'SUCCESS:'
};

// Admin component
export const ADMIN_COMPONENT_CONSTS = {
  ...TOP_NAV_COMPONENT_CONSTS,
  ADMIN_PAGE_HEADING: 'Admin Page',
  LABEL_ANNOUNCEMENT_PERMISSIONS_TAB: 'Announcement Permissions',
  LABEL_CONTENT_MANAGEMENT_TAB: 'Content Management',
  LABEL_NAV_QUICK_LINKS: 'Quick Links',
  LABEL_NAV_GLOBAL_FUNCTIONS: 'Global Functions',
  LABEL_NAV_PROFILE_LINKS: 'Profile Links',
  LABEL_NAV_REGION_WORKPLACE: 'Regional Links',
  LABEL_NAV_TOOLS_RESOURCES: 'Tools and Resources'
};

// Profile links admin component
export const PROFILE_LINKS_ADMIN_COMPONENT_CONSTS = {
  PROFILE_LINKS_SEARCH_PAGINATION_MIN_ITEMS_PER_PAGE: 10,
  PROFILE_LINKS_SEARCH_PAGINATION_ITEMS_PER_PAGE: [5, 10, 15],
  PROFILE_LINKS_SEARCH_RESULTS_COLUMNS: ['title', 'link', 'actions'],
  PLACEHOLDER_SEARCH: 'Search by Title',
  COLUMN_LABLE_TITLE: 'TITLE',
  COLUMN_LABLE_LINK: 'LINK',
  COLUMN_LABLE_ACTIONS: 'ACTIONS',
  ADD_PROFILE_LINKS: 'ADD PROFILE LINK',
  EDIT_PROFILE_LINKS: 'Edit',
  REMOVE_PROFILE_LINKS: 'Remove',
  EDIT_SUCCESS_MESSAGE: 'Profile link has been updated succesfully.',
  ADD_SUCCESS_MESSAGE: 'Profile link has been added succesfully.',
  REMOVE_SUCCESS_MESSAGE: 'Profile link has been removed successfully.',
  SUCCESS_WORD: 'SUCCESS:'
};

// Add Profile links component
export const ADD_PROFILE_LINKS_COMPONENT_CONSTS = {
  TITLE_MAIN: 'Add Profile Link',
  LABLE_TITLE: 'Title',
  PLACEHOLDER_TITLE: 'Profile link title',
  LABLE_LINK: 'Link',
  PLACEHOLDER_LINK: 'URL address',
  LABEL_REQUIRED: 'Required',
  LABEL_EDIT_URL_INVALID: 'URL format is invalid',
  BUTTON_CANCEL: 'CANCEL',
  BUTTON_SAVE: 'SAVE'
};

// Edit Profile links component
export const EDIT_PROFILE_LINKS_COMPONENT_CONSTS = {
  ...ADD_PROFILE_LINKS_COMPONENT_CONSTS,
  TITLE_MAIN: 'Edit Profile Link'
};

export const QUICK_LINK_ADMIN_CONSTS = {
  PLACEHOLDER_SEARCH_QUICK_LINK: 'Search by Title',
  TITLE: 'Title',
  TITLE_PLACEHOLDER: 'Quick link title',
  LINK: 'Link',
  LINK_PLACEHOLDER: 'URL address',
  DESCRIPTION: 'Description',
  DESCRIPTION_PLACEHOLDER: 'Placeholder',
  ACTIONS: 'ACTIONS',
  INDEX: 'Index',
  QUICK_LINK_SEARCH_RESULT_COLUMNS: ['title', 'link', 'description', 'index', 'Actions'],
  QUICK_LINK_SEARCH_PAGINATION_MIN_ITEMS_PER_PAGE: 20,
  QUICK_LINK_SEARCH_PAGINATION_ITEMS_PER_PAGE: [20, 25, 30],
  ADD_TITLE_MAIN: 'Add Quick Link',
  EDIT_TITLE_MAIN: 'Edit Quick Link',
  PLACEHOLDER_LINK: 'URL address',
  LABEL_REQUIRED: 'Required',
  LABEL_INVALID_URL: 'URL format is invalid',
  BUTTON_CANCEL: 'CANCEL',
  BUTTON_SAVE: 'SAVE',
  BUTTON_EDIT: 'SAVE',
  SUCCESS_WORD: 'SUCCESS:',
  FAIL_WORD: 'FAIL',
  ADD_SUCCESS_MESSAGE: 'Quick link has been added successfully.',
  UPDATE_SUCCESS_MESSAGE: 'Quick link has been updated successfully.',
  DELETE_SUCCESS_MESSAGE: 'Quick link has been removed successfully.',
  DUPLICATE_TITLE_MESSAGE: 'Quick link title cannot be duplicate.',
  PEOPLE_SEARCH_URL: '/people-search'
};

export const REGION_ADMIN_CONSTS = {
  PLACEHOLDER_REGION: 'Search by Title',
  TITLE: 'Title',
  ADD_REGIONAL_LINK_TITLE: 'Add regional link',
  EDIT_REGIONAL_LINK_TITLE: 'Edit regional link',
  LINK: 'Link',
  ACTIONS: 'ACTIONS',
  THUMBNAIL: 'Thumbnail',
  CAPTION: 'Caption',
  TYPE: 'Type',
  REGION: 'Region',
  REGION_TYPE: 'Region',
  WORKPLACE_TYPE: 'Workplace',
  REGION_THUMBNAIL_PATH: 'assets/images/regionandworkplace/region/thumbnail',
  WORKPLACE_THUMBNAIL_PATH: 'assets/images/regionandworkplace/workplace/thumbnail',
  IMAGE: 'Image (Regional background image)',
  ThumbnailURL: 'Thumbnail',
  LABEL_REQUIRED: 'Required',
  LABEL_INVALID_URL: 'URL format is invalid',
  REGION_PLACEHOLDER: 'Select countries',
  IMAGE_PLACEHOLDER: 'URL address',
  TITLE_PLACEHOLDER: 'Title',
  LINK_PLACEHOLDER: 'URL address',
  CAPTION_PLACEHOLDER: 'Caption',
  REGION_SEARCH_RESULT_COLUMNS: ['region', 'title', 'link', 'thumbnail', 'actions'],
  REGION_SEARCH_PAGINATION_MIN_ITEMS_PER_PAGE: 20,
  REGION_SEARCH_PAGINATION_ITEMS_PER_PAGE: [20, 25, 30],
  ADD_REGION_MAIN: 'Add Region',
  EDIT_REGION_MAIN: 'Edit Region',
  ADD_WORKPLACE_MAIN: 'Add Workplace',
  EDIT_WORKPLACE_MAIN: 'Edit Workplace',
  BUTTON_CANCEL: 'CANCEL',
  BUTTON_SAVE_REGION: 'SAVE',
  BUTTON_SAVE_WORKPLACE: 'ADD WORKPLACE',
  REGIONS: ['Europe Middle East Africa', 'Americas', 'Asia Pacific', 'INDIA'],
  IMAGES: ['EMEA', 'AMERICAS', 'APAC', 'INDIA'],
  THUMBNAILS: [
    'EMEA Region',
    'EMEA Workplace',
    'AMERICAS Region',
    'AMERICAS Workplace',
    'APAC Region',
    'APAC Workplace',
    'INDIA Region',
    'INDIA Workplace'
  ],
  SUCCESS_WORD: 'SUCCESS:',
  ADD_SUCCESS_MESSAGE: 'Regional link has been added successfully.',
  UPDATE_SUCCESS_MESSAGE: 'Regional link has been updated successfully.',
  DELETE_SUCCESS_MESSAGE: 'Regional link has been deleted successfully.',
  REGION_IMAGE_URL: 'assets/images/regionandworkplace/region/',
  REGION_IMAGE_SUFFIX: '.jpeg',
  REGION_THUMBNAIL_IMAGE_URL:
    'assets/images/regionandworkplace/region/thumbnail/',
  WORKPLACE_THUMBNAIL_IMAGE_URL:
    'assets/images/regionandworkplace/workplace/thumbnail/',
  REGION_THUMBNAIL_IMAGE_SUFFIX: '.JPG'
};

// Admin Tool and Resource
export const TOOL_RESOURCE_COMPONENT_CONSTS = {
  ...PEOPLE_SEARCH_COMPONENT_CONSTS,
  ...TOP_NAV_COMPONENT_CONSTS,
  SEARCH_PAGINATION_MIN_ITEMS_PER_PAGE: 10,
  SEARCH_PAGINATION_ITEMS_PER_PAGE: [5, 10, 15],
  ADMIN_SEARCH_PAGINATION_MIN_ITEMS_PER_PAGE: 50, // Admin page - tools and resources pagination
  ADMIN_SEARCH_PAGINATION_ITEMS_PER_PAGE: [50, 100, 150, 200], // Admin page - tools and resources pagination
  SEARCH_FILTER_ALL: 'All',
  SEARCH_RESULTS_COLUMNS: ['name', 'link', 'keywords', 'Actions'],
  TITLE: 'Add Tool and Resource',
  PLACEHOLDER_SEARCH_ADD_TOOLSLINK: 'Search by Title',
  NAME: 'NAME',
  LINK: 'LINK',
  KEYWORD: 'KEYWORD',
  // PERMISSION_LEVEL: 'Permission Level',
  LINK_EDIT_TR: 'Edit Tool and Resource',
  // TOP_BANNER_QLINK: 'TOP BANNER PERMISSION',
  ACTIONS: 'Actions',
  EDIT_SUCCESS_MESSAGE: 'Tools and Resource has been updated succesfully.',
  ADD_SUCCESS_MESSAGE: 'Tools and Resource has been added succesfully.',
  REMOVE_SUCCESS_MESSAGE: 'Tools and Resource is successfully removed',
  SUCCESS_WORD: 'SUCCESS:',
  BUTTON_CANCEL: 'CANCEL',
  BUTTON_SAVE: 'ADD TOOL AND RESOURCE',
  BUTTON_UPDATE: 'SAVE',
  BUTTON_ADD: 'ADD TOOL AND RESOURCE',
  BUTTON_EDIT_QUICKLINK: 'SUBMIT CHANGES',
  ACTION_CANCEL: 'CANCEL',
  LABEL_NAME: 'Name',
  LABEL_LINK: 'Link',
  LABEL_KEWWORDS: 'Keywords',
  CANCEL_LINK: 'CANCEL',
  // ERROR_CUSTOM_PERMISSION_REQUIRED: '* Grant atleast one permission level to continue',
  // ERROR_REGION_DEPARTMENT_REQUIRED: '* Department or Region name is empty for selected empl
  NAME_PLACEHOLDER: 'Tools and Resource title',
  LINK_PLACEHOLDER: 'URL address',
  TOOL_RESOURCE_PLACEHOLDER: 'TOOL AND RESOURCE',
  KEYWORD_PLACEHOLDER: 'Enter keywords',
  DIRECTADDRESS_PLACEHOLDER: 'URL address',
  LABEL_EDIT_URL_INVALID: 'URL format is invalid'
};
