// Import 3rd-party dependencies
import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

// Import project-specific dependencies
import {
  ICON_GLOBAL_FUNCTION_CONSTS,
  ICON_REGION_CONSTS,
  ICON_SERVICES_CONSTS,
  ICON_WORKPLACE_CONSTS
} from '@app/config/constants';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  consts = ICON_SERVICES_CONSTS;
  gfConsts = ICON_GLOBAL_FUNCTION_CONSTS;
  regionConsts = ICON_REGION_CONSTS;
  workplaceConsts = ICON_WORKPLACE_CONSTS;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
  }

  registerIcons(): void {
    // Load icons from assets folder
    const icons = Object.keys(EIcons);
    icons.forEach((icon) => {
      this.load(icon, EIcons[icon] as string, this.consts.ASSETS_ICONS_LOCATION);
    });

    //Load icons for global functions
    const gfIcons = Object.keys(GFIcons);
    gfIcons.forEach((icon) => {
      this.load(icon, GFIcons[icon] as string, this.gfConsts.ASSETS_GLOBAL_FUNCTION_LOCATION);
    });
  }

  private load(icon: string, filename: string, url: string): void {
    this.matIconRegistry.addSvgIcon(
      icon,
      this.sanitizer.bypassSecurityTrustResourceUrl(`${url}/${filename}${this.consts.SVG_EXT}`)
    );
  }
}

export enum EIcons {
  // File icons
  hub_csv = 'csv',
  hub_pdf = 'pdf',
  hub_ppt = 'pptx',
  hub_pptx = 'pptx',
  hub_web = 'web',
  hub_doc = 'docx',
  hub_docx = 'docx',
  hub_txt = 'txt',
  hub_xlsx = 'xlsx',
  hub_outlook = 'outlook',
  hub_one = 'one',
  hub_onedrive = 'onedrive',
  hub_other = 'other',
  hub_photo = 'photo',
  hub_video = 'video',
  hub_zip = 'zip',
  hub_access = 'accdb',
  hub_visio = 'vsdx',
  hub_project = 'mpp',
  hub_pub = 'pub',
  hub_folder = 'folder',
  hub_xml = 'xml',
  hub_generic = 'generic',
  hub_sharepoint = 'sharepoint',

  // App icons
  hub_announcements_success_tick = 'announcements_success_tick',
  hub_announcements_clock = 'announcements_clock',
  hub_announcements_horn = 'announcements_horn',
  hub_announcements_expired = 'announcements_expired',
  hub_announcements_delete = 'announcements_delete',
  hub_announcements_edit = 'announcements_edit',
  hub_announcements_empty = 'announcements_empty',
  hub_notify = 'notification',
  hub_cloud = 'cloud',
  hub_person = 'person',
  hub_search = 'search',
  hub_search_admin = 'search_admin',
  hub_pre = 'pre',
  hub_next = 'next',
  hub_editfile = 'edit',
  hub_sharedfile = 'sharedfile',
  hub_settings = 'settings_outline',
  hub_drag = 'drag',
  hub_trash = 'trash',
  hub_star_unfilled = 'star_unfilled',
  hub_star_filled = 'star_filled',
  hub_clock = 'clock',
  hub_globe = 'globe',
  hub_poi_unfilled = 'poi_unfilled',
  hub_poi_filled = 'poi_filled',
  hub_person_arrows = 'person_arrows',
  hub_people_refresh = 'people_refresh',
  hub_global_innov = 'global_innov',
  hub_legal_book = 'book',
  hub_calendar = 'calendar',
  hub_info = 'info',
  hub_down_arrow = 'down_arrow',
  hub_plus = 'plus',
  hub_arrow_right = 'arrow_right',
  hub_arrow_down_black = 'arrow_down_black',
  hub_fav_empty = 'fav_empty',
  hub_document = 'document',
  hub_account_setup = 'account_setup',
  hub_dxc_logo = 'dxc_logo_vertical_white',
  hub_dir = 'directory',
  hub_notification_empty = 'notification_empty',
  hub_bell = 'bell',
  hub_article = 'article',
  hub_round_arrow = 'round-arrow',
  hub_filter = 'filter',
  hub_help = 'help',
  hub_close = 'close',
  hub_chevron_left = 'chevron_left',
  hub_chevron_right = 'chevron_right',
  hub_delete_outline = 'delete_outline',
  hub_open_in_new = 'open_in_new',
  hub_keyboard_arrow_down = 'keyboard_arrow_down',
  hub_more_horiz = 'more_horiz',
  hub_link = 'link',
  hub_folder_icon = 'folder_icon',
  hub_home = 'home',
  hub_star_border = 'star_border',
  hub_email = 'email',
  hub_call = 'call',
  hub_chat_bubble_outline = 'chat_bubble_outline',
  hub_chat_bubble = 'chat_bubble',
  hub_email_filled = 'email_filled',
  hub_email_outline = 'email_outline',
  hub_email_contact = 'email_contact',
  hub_info_filled = 'info_filled',
  hub_info_outline = 'info_outline',
  hub_call_filled = 'call_filled',
  hub_call_outline = 'call_outline',
  hub_location_on_filled = 'location_on_filled',
  hub_access_time = 'access_time',
  hub_flash_on = 'flash_on',
  hub_ios_share = 'ios_share',
  hub_perm_identity = 'perm_identity',
  hub_expand_less = 'expand_less',
  hub_expand_more = 'expand_more',
  hub_dxc_logo_black = 'dxc_logo_vertical_black',
  hub_mobile_disclaimer = 'mobile_disclaimer',
  hub_logout = 'logout',
  hub_copy_link = 'copy_link',
  hub_edit_permission = 'edit',
  hub_remove_permission = 'trash',
  hub_profile_projects = 'profile_projects',
  hub_profile_managers = 'profile_managers',
  hub_profile_time = 'profile_time',
  hub_profile_skills = 'profile_skills'
}

//icon in global function admin
export enum GFIcons {
  hub_gl_computer_check = 'computer_check',
  hub_gl_cycle_people = 'cycle_people',
  hub_gl_ethics_and_compliance = 'ethics_and_compliance',
  hub_gl_finance_gavel = 'finance_gavel',
  hub_gl_global_city = 'global_city',
  hub_gl_global_nav = 'global_nav',
  hub_gl_handshake = 'handshake',
  hub_gl_health_people = 'health_people',
  hub_gl_home = 'home',
  hub_gl_message_bubbles = 'message_bubbles',
  hub_gl_money = 'money',
  hub_gl_people_hand = 'people_hand',
  hub_gl_survey_checkbox = 'survey_checkbox',
  hub_gl_website_hand = 'website_hand'
}

export enum RegionIcons {
  hub_region_EMEA = 'EMEA',
  hub_region_AMERICAS = 'AMERICAS',
  hub_region_APAC = 'APAC',
  hub_region_INDIA = 'INDIA'
}

export enum ThumbnailIcons {
  hub_thumbnail_EMEA = 'EMEA',
  hub_thumbnail_AMERICAS = 'AMERICAS',
  hub_thumbnail_APAC = 'APAC',
  hub_thumbnail_INDIA = 'INDIA'
}
