// Import 3rd-party dependencies

// Import project-specific dependencies
import { environment } from '@app/environments/environment';

// URL list to protect
const GRAPH_API_URL = environment.GRAPH.URL;
const PROFILE_API_URL = environment.DEPS.PROFILE_API.URL;
const NEWS_API_URL = environment.DEPS.NEWS_API.URL;
const SEARCHDATA_API_URL = environment.DEPS.SEARCHDATA_API.URL;
const NOTIFICATIONS_API_URL = environment.DEPS.NOTIFICATIONS_API.URL;
const UAM_NOTIFICATIONS_API_URL = environment.DEPS.UAM_NOTIFICATIONS_API.URL;
const ADMIN_API_URL = environment.DEPS.ADMIN_API.URL;

// Other constants
const GRAPH_API_SCOPES = [`${environment.GRAPH.URL}/.default`];
const HUB_API_SCOPES = [`${environment.AZURE.AD.APP_ID_URI}/access_as_user`];

const ProtectedResources = new Map<string, Array<string>>();

// MS Graph default catch-all route
ProtectedResources.set(`${GRAPH_API_URL}/`, GRAPH_API_SCOPES);

// Add DXC Hub API routes here
const routes = [PROFILE_API_URL, NEWS_API_URL, SEARCHDATA_API_URL, NOTIFICATIONS_API_URL, UAM_NOTIFICATIONS_API_URL, ADMIN_API_URL];
routes.forEach((route) => {
  ProtectedResources.set(route, HUB_API_SCOPES);
});

export default ProtectedResources;
