// Import 3rd-party dependencies
import { Injector, NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TeamsMsal2Provider } from '@microsoft/mgt';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

// Import project-specific dependencies
import { setAppInjector } from './app.injector';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MsalAuthModule } from '@app/auth/msal/msal-auth.module';
import { TeamsAuthModule } from '@app/auth/teams/teams-auth.module';
import { ApprovalCentralAuthModule } from '@app/auth/approval-central/approval-central-auth.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TeamsMsal2Provider.isAvailable ? TeamsAuthModule : MsalAuthModule,
    ApprovalCentralAuthModule,
    CommonModule,
    MatSnackBarModule,
    MatAutocompleteModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
