// Import 3rd-party dependencies
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Import project-specific dependencies
import { TeamsAuthGuard } from '@app/auth/teams/teams-auth.guard';
import { TeamsAuthInterceptor } from '@app/auth/teams/teams-auth.interceptor';
import { TeamsAuthService } from '@app/auth/teams/teams-auth.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TeamsAuthInterceptor,
      multi: true
    },
    TeamsAuthGuard,
    TeamsAuthService
  ]
})
export class TeamsAuthModule {}
