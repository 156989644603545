// Import 3rd-party dependencies
import { Injectable } from '@angular/core';
import { BrowserCacheLocation } from '@azure/msal-browser';
import { TeamsMsal2Provider, ProviderState } from '@microsoft/mgt';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as microsoftTeams from '@microsoft/teams-js';

// Import project-specific dependencies
import ProtectedResources from '@app/auth/protected-resources';
import { environment } from '@app/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TeamsAuthService {
  private graphToolkitProvider: TeamsMsal2Provider;

  constructor() {
    TeamsMsal2Provider.microsoftTeamsLib = microsoftTeams;
    this.graphToolkitProvider = new TeamsMsal2Provider({
      clientId: environment.AZURE.AD.CLIENT_ID,
      authPopupUrl: `${window.location.origin}/teams-auth.html`,
      scopes: [`${environment.GRAPH.URL}/.default`],
      msalOptions: {
        auth: {
          clientId: environment.AZURE.AD.CLIENT_ID,
          authority: environment.AZURE.AD.AUTHORITY,
          redirectUri: '/'
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          secureCookies: true
        }
      },
      autoConsent: false
    });
  }

  getGraphToolkitProvider(): TeamsMsal2Provider {
    return this.graphToolkitProvider;
  }

  login(url: string): Observable<string> {
    const scopes = this.getProtectedUrlScopes(url);
    return scopes ? this.getAccessToken(scopes) : (of(null) as Observable<string>);
  }

  private isLoggedIn(): boolean {
    return this.graphToolkitProvider.state === ProviderState.SignedIn;
  }

  private getAccessToken(scopes: string[]): Observable<string> {
    // Request MS Graph access token; Teams Provider handles the caching and refresh of auth token
    return of(this.isLoggedIn()).pipe(
      switchMap((result: boolean) => {
        if (!result) {
          return from(this.graphToolkitProvider.trySilentSignIn()).pipe(
            map(() => this.isLoggedIn()),
            catchError(() => of(false))
          );
        } else {
          return of(result);
        }
      }),
      switchMap((result: boolean) => {
        if (!result) {
          return from(this.graphToolkitProvider.login()).pipe(map(() => true));
        } else {
          return of(result);
        }
      }),
      switchMap(() => {
        return from(
          this.graphToolkitProvider.getAccessToken({
            scopes: scopes
          })
        );
      })
    );
  }

  private getProtectedUrlScopes(targetUrl: string): string[] {
    const iterator = ProtectedResources.keys();
    let sourceUrl = iterator.next();
    let scopes: string[] = null;

    while (!scopes && !sourceUrl.done) {
      scopes = targetUrl.includes(sourceUrl.value) ? ProtectedResources.get(sourceUrl.value) : null;
      sourceUrl = iterator.next();
    }

    return scopes;
  }
}
