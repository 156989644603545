// Import 3rd-party dependencies
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

// Import project-specific dependencies
import { TeamsAuthService } from '@app/auth/teams/teams-auth.service';

@Injectable({
  providedIn: 'root'
})
export class TeamsAuthGuard implements CanActivate {
  constructor(private authService: TeamsAuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot // eslint-disable-line @typescript-eslint/no-unused-vars
  ): Observable<boolean> {
    return this.authService.login(next.url.toString()).pipe(
      switchMap(() => {
        return of(true);
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
}
